import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useForm from '../../hooks/useForm';
import { filtersform } from '../../formsConfig/formsConfig';
import Fade from '../../components/Fade/Fade';
import { Input, Button, Loader, Box, Flex, Text, Modal, Col, Wrapper } from '../../components/Ui';
import { getProducts, closeOrderModal, getAllProducts } from '../../store/actions/products';
import Product from '../../components/product';
import styled, { useTheme } from 'styled-components/macro';
import Recensione from '../../components/recensione';
import { IoIosClose } from 'react-icons/io';
import ReactShadowScroll from 'react-shadow-scroll';
import Pagination from 'react-js-pagination';
import { respondTo } from '../../theme/mixin';
import ProductDetails from '../../components/product/productDetails';
import { numberWithDots } from '../../utils/utils';
import { GiSettingsKnobs } from 'react-icons/gi';
import { addToCartClearError } from '../../store/actions/wishlist';
import { useLocation } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import useMedia from '../../hooks/useMedia';

const Aside = styled.div`
  width: 300px;
  flex-shrink: 0;
`;
const Form = styled.form`
  /*   display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 10px;
  & > :nth-child(n) {
    grid-column: 1/3;
  }
  & > :nth-child(8) {
    grid-column: 1/2;
  }
  & > :nth-child(9) {
    grid-column: 2/3;
  } */
  .searchMobile {
    display: block;
    ${respondTo.sm`
    display:none;
    `}
  }
  .searchDesktop {
    display: none;
    ${respondTo.sm`
    display:block;
    `}
  }
  .points {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: flex-end;
    input {
      width: 100%;
    }
    & > div {
      width: 48%;
    }
  }
  label {
    color: ${({ theme }) => theme.colore_testo_box};
  }
`;
const Punti = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > p {
    width: 100%;
  }
  & > div {
    width: 48%;
  }
`;
const Btns = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
`;
const CustomFlexProducts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 60px;
  //margin-top: 80px;
  ${respondTo.md78`
       grid-template-columns: 1fr 1fr 1fr;
       margin-top: 0;
  `};
`;
const CustomText = styled(Text)`
  padding: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.borderCode};
  display: flex;
  align-items: center;
  border-radius: 3px;
  font-size: 12px;
  span {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  svg {
    margin-left: 3px;
    color: red;
    cursor: pointer;
    font-size: 18px;
  }
`;
const Filters = styled.div`
  display: none;
  flex-wrap: wrap;
  ${respondTo.sm`
    display:flex;
    `}
`;
const FiltersMobile = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  & > span {
    margin: 0;
  }
  ${respondTo.sm`
    display:none;
    `}
`;

const CustomBox = styled(Box)`
  display: none;
  ${respondTo.sm`
    display:block;
    `}
`;
const CustomBoxMobile = styled(Box)`
  display: block;
  ${respondTo.sm`
    display:none;
    `}
`;
const ContentFilter = styled.div`
  height: 0;
  overflow: hidden;
  transition: 0.4s;
  margin-top: 75px !important;

  ${respondTo.sm`
  position: static;
      margin-top: 0 !important;
      height:auto !important;
  `}
`;
const CustomButtonFilter = styled(Button)`
  padding: 0 10px;
  justify-content: space-between;
  gap: 10px;
  svg {
    font-size: 25px;
    color: ${({ theme }) => theme.colore_testo_bottoni};
    margin-left: 0;
  }
  span {
    @media (max-width: 385px) {
      font-size: 16px;
    }
    @media (max-width: 280px) {
      font-size: 14px;
    }
  }
  ${respondTo.sm`
    display:none;
    `}
`;
const FilterBtn = styled.div`
  z-index: 99;

  // padding: 20px 0 10px 0;
  display: block;
  ${respondTo.sm`
    display:none;
    `}
`;
const CustomFlexMacroCatwgory = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 60px;
  .macroCategoryBox {
    border-radius: ${({ theme }) => theme.border_radius_generale_grande};
    overflow: hidden;

    cursor: pointer;

    img {
      width: 100%;
      display: block;
    }
  }
`;
const CustomFlex = styled(Flex)`
  display: flex;
  gap: 10px;
  position: fixed;
  flex-wrap: wrap;
  z-index: 997;
  top: ${props => props.theme.header_mobile_height + 'px'};
  /* top: 96px; */
  background-color: ${({ theme }) => theme.bg_general};
  left: 0;
  right: 0;
  padding: 10px 10px 5px;

  ${respondTo.sm`
  display:none;
    `}
`;
const DesktopScoreBox = styled(Box)`
  display: none;
  border-radius: ${({ border_radius_generale_piccolo }) => border_radius_generale_piccolo};
  ${respondTo.sm`
  display:block;
    `}
`;
const MobileScoreBox = styled(Box)`
  flex-grow: 1;
  width: 60%;
  // width: auto;
  height: 50px;
  border-radius: ${({ border_radius_generale_piccolo }) => border_radius_generale_piccolo};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h3,
  small {
    @media (max-width: 420px) {
      font-size: 16px;
    }
    @media (max-width: 390px) {
      font-size: 14px;
    }
    @media (max-width: 280px) {
      font-size: 12px;
    }
  }
  span {
    @media (max-width: 280px) {
      font-size: 14px;
    }
  }

  ${respondTo.sm`
    display:none;
  `}
`;
const CatalogTop = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 50px;
  display: none;
  img {
    display: block;
    margin-top: 10px;
    margin-bottom: 30px;
    width: 100%;
  }
  ${respondTo.sm`
    display: block;
  `}
`;
const CatalogTopMobile = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  display: block;
  img {
    display: block;
    margin-top: 10px;
    margin-bottom: 30px;
    width: 100%;
  }
  ${respondTo.sm`
    display: none;
  `}
`;
const CatalogBottom = styled.div`
  text-align: center;
  margin-top: 60px;
  video,
  iframe {
    max-width: 100%;
  }
  ${respondTo.md`
    margin-bottom: 30px ;
  `}
`;

const CatalogMiddle = styled(Flex)`
  .filter {
    padding: 0 5px 0 0;

    ${respondTo.md`
    padding: 20px 30px 0 0;
    `};
  }
  .products {
    padding: 0 0 0 5px;

    ${respondTo.md`
    padding: 20px 0 0 30px;
    `};
  }
`;

const Catalog = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();
  let queryCategory = query.get('categories');
  let queryMacroCategory = query.get('macro_categories');
  let queryBrand = query.get('brand');
  const { products, loading, id_catalog } = useSelector(state => state.products);
  const pointsLabel = useSelector(state => state.app.config.points_label);

  const filtersForm = filtersform(
    {
      categories: queryCategory,
      brand: queryBrand,
      macro_categories: queryMacroCategory,
      [location.state?.auto_filter]: location.state?.auto_filter_value,
    },
    pointsLabel,
  );

  const { score, score_pending } = useSelector(state => state.user.user.userinfo || {});
  const { cart, wishlist } = useSelector(state => state.user.user || {});
  const error = useSelector(state => state.user.cartError);
  const { base_threshold_enabled, base_threshold_percent, base_threshold_description } =
    useSelector(state => state.user.user || {});
  const { catalog: catalogProducts } = useSelector(state => state.cms);
  const show_catalog = useSelector(state => state.app.config.settings.show_catalog);
  const macrocategory_filter_enabled = useSelector(
    state => state.app.config.macrocategory_filter_enabled,
  );

  const [isReviews, setIsReviews] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [filters, setFilters] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [brandSelect, setBrandSelect] = useState();
  const [catSelect, setCatSelect] = useState();
  const [macroSelect, setMacroSelect] = useState();
  //const [areaSelect, setAreaSelect] = useState();
  const [inputSelected, setInputSelected] = useState();
  const [puntiAbbastanza, setpuntiAbbastanza] = useState(false);

  const [filterToggel, setFilterToggel] = useState(false);
  const ref = useRef();
  const filterRef = useRef();
  const submitRef = useRef();
  const theme = useTheme();
  const media = useMedia('(max-width: 767px)');

  const catalogTop = useSelector(state => state.cms.catalogTop);

  const catalogBottom = useSelector(state => state.cms.catalogBottom);

  const catalogTop_content = catalogTop[0].contents.find(el => el.name === 'catalog_top');

  const catalog_Bottom = catalogBottom[0].contents.find(el => el.name === 'catalog_bottom');

  useEffect(() => {
    dispatch(getAllProducts()).then(res => {
      const { products } = res || {};
      if (
        location.state?.auto_filter === 'categories' &&
        location.state?.auto_filter_value &&
        products
      ) {
        dispatch(getProducts(1, formData));
        const filter = products?.categories.find(
          el => el.description === location.state.auto_filter_value,
        )?.brands;
        const filterMacro = products.categories.find(
          el => el.description === location.state.auto_filter_value,
        ).macroCategories;
        setBrandSelect(filter);
        setMacroSelect(filterMacro);
      }

      if (queryCategory && products) {
        const filter = products?.categories.find(el => el.description === queryCategory)?.brands;
        const filterMacro = products.categories.find(
          el => el.description === queryCategory,
        ).macroCategories;
        setBrandSelect(filter);
        setMacroSelect(filterMacro);
      }
      if (
        location.state?.auto_filter === 'macro_categories' &&
        location.state?.auto_filter_value &&
        products
      ) {
        dispatch(
          getProducts(1, {
            ...formData,
            macroCategory: {
              value: products.macroCategories.find(
                el => el.description === location.state.auto_filter_value,
              )?.code,
            },
          }),
        );
        const filterBrand = products.macroCategories.find(
          el => el.description === location.state.auto_filter_value,
        ).brands;
        const filterCat = products.macroCategories.find(
          el => el.description === location.state.auto_filter_value,
        )?.categories;
        setBrandSelect(filterBrand);
        setCatSelect(filterCat);
      }
      if (queryMacroCategory && products) {
        const filterBrand = products.macroCategories.find(
          el => el.description === queryMacroCategory,
        ).brands;
        const filterCat = products.macroCategories.find(
          el => el.description === queryMacroCategory,
        )?.categoriess;
        setBrandSelect(filterBrand);
        setCatSelect(filterCat);
      }
      if (queryBrand && products) {
        const filterCat = products.brands.find(el => el.description === queryBrand)?.categories;
        const filterMacro = products.brands.find(
          el => el.description === queryBrand,
        )?.macroCategories;
        setCatSelect(filterCat);
        setMacroSelect(filterMacro);
      }
      if ((queryBrand || queryMacroCategory || queryCategory) && products) {
        dispatch(
          getProducts(1, {
            ...formData,
            brand: {
              value: products.brands.find(el => el.description === queryBrand)?.code,
            },
            macroCategory: {
              value: products.macroCategories.find(el => el.description === queryMacroCategory)
                ?.code,
            },
          }),
        );
      }
    });
  }, []);

  const {
    product,
    orderModal,
    loading: singleProductLoading,
  } = useSelector(state => state.product);
  const catalogs = useSelector(
    state => state.products?.products?.catalogs?.filter(cat => +cat.is_area && +cat.is_base) || [],
  );
  const allProd = useSelector(state => state.products.products);
  const appName = useSelector(state => state.app.config.code);

  const filterProductsHandler = () => {
    setActivePage(1);
    console.log('from fn');
    dispatch(
      getProducts(1, {
        ...formData,
        ...(queryBrand &&
          !formData.brand.touched &&
          formData.brand.value && {
            brand: {
              value: products.brands.find(el => el.description === queryBrand)?.code,
            },
          }),
        ...((queryMacroCategory || location.state?.auto_filter === 'macro_categories') &&
          !formData.macroCategory.touched &&
          formData.macroCategory.value && {
            macroCategory: {
              value: products.macroCategories.find(
                el => el.description === (queryMacroCategory || location.state?.auto_filter_value),
              )?.code,
            },
          }),
      }),
    );
  };

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    datePickerHandler,
    resetFormHandler,
    resetInputHandler,
  } = useForm(filterProductsHandler, filtersForm, null, null, null, null, null, null, null, true);
  const filterHandler = e => {
    e.preventDefault();
    formSubmitHandler(e);
    setIsFilter(prev => !prev);
  };
  const resetForm = () => {
    setCatSelect();
    setBrandSelect();
    setMacroSelect();
    resetFormHandler();
    setIsFilter(prev => !prev);
    filterProductsHandler();
  };

  useLayoutEffect(() => {
    dispatch(closeOrderModal());
  }, []);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  const inputChangedLocally = e => {
    setInputSelected(e.target.name);
  };

  useEffect(() => {
    if (inputSelected && products) {
      const inputValue = inputArr.find(item => item.name === inputSelected).value;
      if (inputValue === '') {
        setCatSelect();
        setBrandSelect();
        setMacroSelect();
      } else {
        if (inputSelected === 'category') {
          const filter = products.categories.find(el => el.description === inputValue)?.brands;
          const filterMacro = products.categories.find(
            el => el.description === inputValue,
          ).macroCategories;
          setBrandSelect(filter);
          setMacroSelect(filterMacro);

          if (
            !filterMacro.find(item => {
              if (formData.macroCategory.touched) {
                return item.code === formData.macroCategory.value;
              }
              return item.description === formData.macroCategory.value;
            })
          ) {
            resetInputHandler('macroCategory');
          }
          if (
            !filter.find(item => {
              if (formData.brand.touched) {
                return item.code === formData.brand.value;
              }
              return item.description === formData.brand.value;
            })
          ) {
            resetInputHandler('brand');
          }
        }
        if (inputSelected === 'macroCategory') {
          const filterBrand = products.macroCategories.find(el => el.code === inputValue).brands;
          const filterCat = products.macroCategories.find(el => el.code === inputValue)?.categories;
          setBrandSelect(filterBrand);
          setCatSelect(filterCat);

          if (!filterCat.find(item => item.description === formData.category.value)) {
            resetInputHandler('category');
          }
          if (
            !filterBrand.find(item => {
              if (formData.brand.touched) {
                return item.code === formData.brand.value;
              }
              return item.description === formData.brand.value;
            })
          ) {
            resetInputHandler('brand');
          }
        }

        if (inputSelected === 'brand') {
          const filterCat = products.brands.find(el => el.code === inputValue).categories;
          const filterMacro = products.brands.find(el => el.code === inputValue).macroCategories;
          setCatSelect(filterCat);
          setMacroSelect(filterMacro);

          if (!filterCat.find(item => item.description === formData.category.value)) {
            resetInputHandler('category');
          }
          if (
            !filterMacro.find(item => {
              if (formData.macroCategory.touched) {
                return item.code === formData.macroCategory.value;
              }
              return item.description === formData.macroCategory.value;
            })
          ) {
            resetInputHandler('macroCategory');
          }
        }
      }

      setInputSelected('');
    }
  }, [inputSelected]);

  useEffect(() => {
    if (filters.length === 0 || filters.length === 1) {
      setCatSelect();
      setBrandSelect();
      setMacroSelect();
    }
    if (filters.length === 1) {
      setInputSelected(filters[0].name);
    }
    if (ref.current && filterRef.current && media) {
      ref.current.style.marginTop = 85 + filterRef.current.scrollHeight + 'px';
    } else {
      ref.current.style.marginTop = 0;
    }
  }, [filters, media]);

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={e => {
          inputChangedLocally(e);
          inputChangedHandler(e);
        }}
        label={inp.label}
        istouched={inp.validation.touched}
        showError={showError}
        changedDatePicker={datePickerHandler}
        resetFormHandler={resetFormHandler}
        categories={catSelect ? catSelect : products?.categories}
        brands={brandSelect ? brandSelect : products?.brands}
        macroCategories={macroSelect ? macroSelect : products?.macroCategories}
        catalogs={catalogs}
        {...inp}
        value={
          inp.name === 'brand' && queryBrand && inp.value !== '' && !inp.touched
            ? products?.brands?.find(el => el.description === queryBrand)?.code
            : inp.name === 'macroCategory' &&
              (queryMacroCategory || location.state?.auto_filter_value) &&
              inp.value !== '' &&
              !inp.touched
            ? products?.macroCategories?.find(
                el => el.description === (queryMacroCategory || location.state?.auto_filter_value),
              )?.code
            : inp.value
        }
      />
    );
  });

  const resetInput = val => {
    resetInputHandler(val);
    filterProductsHandler();
    setIsFilter(prev => !prev);
  };

  useEffect(() => {
    setFilters(inputArr.filter(item => item.value !== ''));
  }, [isFilter]);

  const handlePageChange = pageNumber => {
    setActivePage(pageNumber);
    if (filters.length > 0) {
      dispatch(
        getProducts(pageNumber, {
          ...formData,
          ...(queryBrand &&
            !formData.brand.touched &&
            formData.brand.value && {
              brand: {
                value: products.brands.find(el => el.description === queryBrand)?.code,
              },
            }),
          ...((queryMacroCategory || location.state?.auto_filter === 'macro_categories') &&
            !formData.macroCategory.touched &&
            formData.macroCategory.value && {
              macroCategory: {
                value: products.macroCategories.find(
                  el =>
                    el.description === (queryMacroCategory || location.state?.auto_filter_value),
                )?.code,
              },
            }),
        }),
      ).then(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    } else {
      dispatch(getAllProducts(pageNumber)).then(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    }
  };
  const puntiModalHandler = () => {
    setpuntiAbbastanza(prev => !prev);
  };

  useEffect(() => {
    if (filterToggel) {
      ref.current.style.height = 'fit-content';
    } else {
      ref.current.style.height = 0;
    }
  }, [filterToggel]);
  const clickMacroCategoryHandler = value => {
    inputChangedHandler({ target: { name: 'macroCategory', value } });
    setInputSelected('macroCategory');

    setTimeout(() => {
      submitRef.current.click();
    }, 0);
  };

  return (
    <>
      <Wrapper>
        {(catalogTop_content?.i18l.title ||
          catalogTop_content?.img ||
          catalogTop_content.i18l.content) && (
          <CatalogTop>
            {catalogTop_content?.i18l.title && (
              <Text bold primary>
                {catalogTop_content?.i18l.title}
              </Text>
            )}
            {catalogTop_content?.img && <img src={catalogTop_content?.img} alt='' />}
            {catalogTop_content.i18l.content && <Text>{catalogTop_content.i18l.content}</Text>}
          </CatalogTop>
        )}
        <CatalogMiddle justify='space-between' align='stretch'>
          <Col widthPx='300px' style={{ marginBottom: 5 }} className='filter'>
            <CustomFlex>
              <FilterBtn>
                <CustomButtonFilter
                  active
                  onClick={() => {
                    setFilterToggel(prev => !prev);
                    window.scrollTo(0, 0);
                  }}
                >
                  <GiSettingsKnobs />
                  <Text size={16} bold white>
                    Filtra
                  </Text>
                </CustomButtonFilter>
              </FilterBtn>
              {Boolean(show_catalog) && (
                <>
                  <MobileScoreBox>
                    <Text align='center' as='h3' bold type='mediumTitle' text_box>
                      Hai a disposizione {numberWithDots(score)} punti
                    </Text>

                    {appName && appName === 'WURTH' ? (
                      <Text align='center' size={18} text_box as='small'>
                        Punti da confermare: {numberWithDots(score_pending)}
                      </Text>
                    ) : null}
                  </MobileScoreBox>
                  <FiltersMobile ref={filterRef}>
                    {filters?.map(item => {
                      return (
                        <CustomText text_box>
                          {products?.brands?.find(elem => elem.code === item.value)
                            ? products?.brands?.find(elem => elem.code === item.value)?.description
                            : products?.macroCategories?.find(elem => elem.code === item.value)
                            ? products?.macroCategories?.find(elem => elem.code === item.value)
                                ?.description
                            : item.value}
                          <span onClick={() => resetInput(item.name)}>
                            <IoIosClose />
                          </span>
                        </CustomText>
                      );
                    })}
                  </FiltersMobile>
                </>
              )}
            </CustomFlex>
            {Boolean(show_catalog) && (
              <div
                style={{
                  position: 'sticky',
                  backgroundColor: theme.bg_general,
                  top: 0,
                  zIndex: 99,
                  padding: '1px 0',
                  marginBottom: '1px',
                }}
              >
                <DesktopScoreBox margin='0 0 20px 0'>
                  <Text align='center' as='h3' bold type='mediumTitle' text_box>
                    Hai a disposizione {numberWithDots(score)} punti
                  </Text>

                  {appName && appName === 'WURTH' ? (
                    <Text align='center' size={18} text_box margin='5px 0'>
                      Punti da confermare: {numberWithDots(score_pending)}
                    </Text>
                  ) : null}
                </DesktopScoreBox>
              </div>
            )}
            <ContentFilter ref={ref}>
              <Box margin='0 0 20px 0'>
                <Text as='h3' bold size={20} text_box>
                  Filtro rapido
                </Text>
                <Filters>
                  {filters?.map(item => {
                    return (
                      <CustomText text_box>
                        {products?.brands?.find(elem => elem.code === item.value)
                          ? products?.brands?.find(elem => elem.code === item.value)?.description
                          : products?.macroCategories?.find(elem => elem.code === item.value)
                          ? products?.macroCategories?.find(elem => elem.code === item.value)
                              ?.description
                          : item.value}
                        <span onClick={() => resetInput(item.name)}>
                          <IoIosClose />
                        </span>
                      </CustomText>
                    );
                  })}
                </Filters>
                <Form onSubmit={e => filterHandler(e)}>
                  <div className='searchMobile'>{inputs[5]}</div>
                  <div>{inputs[0]}</div>
                  <div>{inputs[1]}</div>
                  <div>{inputs[2]}</div>
                  <div className='points'>
                    <div> {inputs[3]}</div>
                    <div> {inputs[4]}</div>
                  </div>
                  <div className='searchDesktop'>{inputs[5]}</div>

                  <Btns>
                    <Button
                      active
                      type='submit'
                      onClick={() => setFilterToggel(false)}
                      ref={submitRef}
                      style={{ padding: '0 16px' }}
                    >
                      <Text upper bold white>
                        Filtra
                      </Text>
                    </Button>
                    <Button
                      style={{ padding: '0 5px' }}
                      type='button'
                      transparent
                      color='#000000'
                      onClick={() => resetForm()}
                    >
                      <Text as='p' size={16} upper bold text_box>
                        Annulla Filtro
                      </Text>
                    </Button>
                  </Btns>
                </Form>
              </Box>
            </ContentFilter>

            {base_threshold_enabled && (
              <Box margin='0 0 20px 0'>
                <Text align='center' as='h3' bold type='mediumTitle' className='mb-10' text_box>
                  Soglia base {base_threshold_description}
                </Text>
                <Text align='center' as='h3' bold type='mediumTitle' className='mb-10' text_box>
                  Hai raggiunto il
                </Text>
                <Text align='center' as='h3' bold type='title' primary className='mb-10' text_box>
                  {base_threshold_percent}
                </Text>
                <Text align='center' as='h3' bold type='mediumTitle' text_box>
                  della soglia base
                </Text>
              </Box>
            )}

            {catalogProducts[0]?.contents[0]?.products?.length > 0 && (
              <CustomBox margin='0 0 20px 0'>
                <Text align='center' as='h3' bold type='mediumTitle' margin='0 0 10px 0' text_box>
                  {catalogProducts[0]?.contents[0]?.i18l.title}
                </Text>
                {catalogProducts[0]?.contents[0]?.products?.map(item => (
                  <Product
                    key={item.id}
                    product={item}
                    bottom={25}
                    horiz
                    setIsReviews={setIsReviews}
                  />
                ))}
              </CustomBox>
            )}
          </Col>
          {(catalogTop_content?.i18l.title ||
            catalogTop_content?.img ||
            catalogTop_content.i18l.content) && (
            <CatalogTopMobile>
              {catalogTop_content?.i18l.title && (
                <Text bold primary>
                  {catalogTop_content?.i18l.title}
                </Text>
              )}
              {catalogTop_content?.img && <img src={catalogTop_content?.img} alt='' />}
              {catalogTop_content.i18l.content && <Text>{catalogTop_content.i18l.content}</Text>}
            </CatalogTopMobile>
          )}
          {loading ? (
            <Loader show={loading} flexGrow={1} />
          ) : (
            <>
              <Col style={{ flex: 1 }} className='products'>
                {filters.length > 0 || !macrocategory_filter_enabled ? (
                  <>
                    <CustomFlexProducts>
                      {products?.products.length > 0 ? (
                        products?.products
                          ?.sort((a, b) => a.points - b.points)
                          .map(item => {
                            return (
                              item.images.length > 0 && (
                                <Product
                                  key={item.id}
                                  product={item}
                                  catalogId={item.catalog_id}
                                  border
                                  //bottom={25}
                                  setIsReviews={setIsReviews}
                                  setpuntiAbbastanza={setpuntiAbbastanza}
                                />
                              )
                            );
                          })
                      ) : (
                        <Text
                          style={{
                            textAlign: 'center',
                            margin: '50px auto',
                          }}
                        >
                          Non ci sono prodotti che soddisfano i criteri di ricerca
                        </Text>
                      )}
                    </CustomFlexProducts>
                    {products?.total_pages > 1 && (
                      <Pagination
                        totalItemsCount={products.total_pages * 18}
                        onChange={handlePageChange}
                        activePage={activePage}
                        itemsCountPerPage={18}
                        pageRangeDisplayed={5}
                      />
                    )}
                    {catalogProducts[0]?.contents[0]?.products?.length > 0 && (
                      <CustomBoxMobile margin='20px 0 20px 0'>
                        <Text align='center' as='h3' bold type='mediumTitle' margin='0 0 10px 0'>
                          {catalogProducts[0]?.contents[0]?.i18l.title}
                        </Text>
                        {catalogProducts[0]?.contents[0]?.products?.map(item => (
                          <Product
                            key={item.id}
                            product={item}
                            bottom={25}
                            horiz
                            setIsReviews={setIsReviews}
                          />
                        ))}
                      </CustomBoxMobile>
                    )}
                  </>
                ) : (
                  <CustomFlexMacroCatwgory>
                    {products?.macroCategories.length > 0 &&
                      products?.macroCategories.map(item => (
                        <div
                          className='macroCategoryBox'
                          onClick={() => clickMacroCategoryHandler(item.code)}
                        >
                          <picture>
                            <source media='(max-width: 768px)' srcset={item.img_mobile} />
                            <source media='(min-width: 769px)' srcset={item.img_desktop} />
                            <img src={item.img_desktop} alt='' />
                          </picture>
                        </div>
                      ))}
                  </CustomFlexMacroCatwgory>
                )}
              </Col>
            </>
          )}
        </CatalogMiddle>

        {(catalog_Bottom?.i18l.title || catalog_Bottom?.img || catalog_Bottom?.i18l.content) && (
          <CatalogBottom>
            {catalog_Bottom?.i18l.title && (
              <Text bold primary>
                {catalog_Bottom?.i18l.title}
              </Text>
            )}
            {catalog_Bottom?.img && <img src={catalog_Bottom?.img} alt='' />}
            {catalog_Bottom?.i18l.content && <Text as='div'>{catalog_Bottom?.i18l.content}</Text>}
          </CatalogBottom>
        )}
      </Wrapper>

      <Modal
        show={orderModal.isOpen || puntiAbbastanza || Boolean(error)}
        close={() => {
          dispatch(closeOrderModal());
          setpuntiAbbastanza(false);
          dispatch(addToCartClearError());
        }}
        isFixed={!isReviews}
        alert={Boolean(error)}
      >
        {singleProductLoading && !isReviews ? (
          <Loader show={singleProductLoading} />
        ) : Boolean(error) ? (
          <Text>{error}</Text>
        ) : isReviews ? (
          <Recensione product={product} />
        ) : (
          <ReactShadowScroll
            style={{ width: '100%', maxHeight: '80vh' }}
            isShadow={false}
            scrollColor={'#cccccc'}
            scrollColorHover='gray'
            styleSubcontainer={{ overflowX: 'hidden' }}
          >
            {!puntiAbbastanza && orderModal.isOpen ? (
              <ProductDetails
                product={
                  cart?.find(item => item.id === product.id)
                    ? cart?.find(item => item.id === product.id)
                    : wishlist?.find(item => item.id === product.id)
                    ? wishlist?.find(item => item.id === product.id)
                    : product
                }
                setIsReviews={setIsReviews}
                setpuntiAbbastanza={setpuntiAbbastanza}
              />
            ) : (
              <Text> Non hai abbastanza punti</Text>
            )}
          </ReactShadowScroll>
        )}
      </Modal>
    </>
  );
};

export default Catalog;
